.wrapper,
.postWrapper {
  composes: page from "../pages.module.css";
}

.wrapper {
  @apply pt-10 md:pt-16;
}

.subtitle {
  @apply text-gray-700 text-xl;
}

.content {
  @apply prose prose-indigo prose-lg m-auto;
  line-height: 1.625;
  max-width: 42rem;
}

.content .author {
  @apply italic text-base md:text-lg mt-6 mb-0 pb-6 md:pb-10;
}

.content .heroImage {
  @apply mt-0;
}

.content h1 {
  @apply text-4xl mb-4 leading-normal;
}

.content h2 {
  @apply text-2xl leading-normal;
}

.content h3 {
  @apply text-xl leading-normal;
}

.content h4 {
  @apply text-lg leading-normal;
}

.content a {
  font-weight: inherit;
}

.content b,
.content strong {
  color: inherit;
}

.searchForm {
  composes: searchForm from "../pages.module.css";
}

.searchField {
  composes: searchField from "../pages.module.css";
}

.clearSearch {
  composes: clearSearch from "../pages.module.css";
}

.blogPostsGrid {
  composes: blogPostsGrid from "../pages.module.css";
}

.recommendedBlogPostsGrid {
  composes: blogPostsGrid from "../pages.module.css";
  @apply lg:grid-cols-2;
}

.tag {
  @apply mt-1 mr-2 px-2 py-1 text-white rounded-md text-sm cursor-pointer;
}

.tag.hiddenTag {
  @apply bg-opacity-50;
}

.tag-green {
  @apply bg-green-400 hover:bg-green-500;
}

.tag-blue {
  @apply bg-blue-400 hover:bg-blue-500;
}

.tag-yellow {
  @apply bg-yellow-400 hover:bg-yellow-500;
}

.tag-gray {
  @apply bg-gray-400 hover:bg-gray-500;
}

.tag-indigo {
  @apply bg-indigo-400 hover:bg-indigo-500;
}

.tag-pink {
  @apply bg-pink-400 hover:bg-pink-500;
}

.tag-red {
  @apply bg-red-400 hover:bg-red-500;
}

.socialMediaButton {
  @apply mr-2 mb-2 text-white inline-flex px-4 py-2 sm:py-3 border-transparent font-medium rounded-md shadow-sm;
}

.twitterButton {
  composes: socialMediaButton;
  background-color: rgba(29, 161, 242, 1);
}

.twitterButton:hover {
  background-color: rgba(29, 161, 242, 0.9);
}

.linkedInButton {
  composes: socialMediaButton;
  background-color: rgba(0, 119, 181, 1);
}

.linkedInButton:hover {
  background-color: rgba(0, 119, 181, 0.9);
}

.ctaButton {
  @apply inline-block text-center px-6 py-3 text-base font-medium rounded-md shadow-sm text-white no-underline bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov;
}
